<template>
    <div class="container py-5">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard">Admin Dashboard</router-link></li>
                <li class="breadcrumb-item"><router-link to="/admindatasets">Datasets</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">Dataset</li>
            </ol>
        </nav>
        <h1>Edit Dataset: {{dataset.name}}</h1>
        <small class="text-muted">Dataset id: {{dataset.id}}</small>
        <form @submit.prevent="handleSubmit">
            <div class="mb-3 row">
                <label for="name" class="col-sm-2 col-form-label">Name:</label>
                <div class="col-sm-10">
                    <input v-model="dataset.name" type="text" class="form-control" id="name" placeholder="name">
                </div>
            </div>

            <div class="mb-3 row">
                <label for="shortDescription" class="col-sm-2 col-form-label">Description:</label>
                <div class="col-sm-10">
                    <textarea v-model="dataset.shortDescription" class="form-control" id="shortDescription" rows="3" placeholder="short description"></textarea>
                </div>
            </div>

            <div class="mb-3 row">
                <label for="image" class="col-sm-2 col-form-label">Image:</label>
                <div class="col-sm-6">
                    <div class="btn-group">
                        <button type="button" class="btn btn-light dropdown-toggle" data-bs-toggle="dropdown">Select image</button>
                        <div class="dropdown-menu" style="max-height:500px; overflow:scroll; width:550px;">
                            <a v-for="(image, index) in images" :key="index" href="#" class="dropdown-item" @click.stop.prevent="dataset.image= '/images/' + image">
                                <div style="display: inline-flex; align-items: center;">
                                    <div class="d-flex flex-wrap align-items-center" style="width: 155px; border: 1px silver solid; background:white;">
                                        <img :src="'/images/' + image" style="object-fit: scale-down; height:150px; width:150px; padding:0px;" />
                                    </div>
                                    <div class="ms-2">{{image}}</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 align-content-end">
                    <div class="d-flex flex-wrap align-items-center align-content-center float-end" style="border: 1px solid silver; padding: 2px; width: 155px; height: 155px;">
                        <img v-if="dataset.image" :src="dataset.image" style="object-fit: scale-down; height:150px; width:150px;" />
                    </div>
                </div>
            </div>

            <div class="mb-3 row">
                <label for="tags" class="col-sm-2 col-form-label">Tags:</label>
                <div class="col-sm-5">
                    <textarea v-model="tags" type="text" class="form-control" id="tags" rows="3" placeholder="tags, comma delimited" />
                </div>
                <div class="col-sm-5">
                    <div class="card">
                        <div class="card-header">Tags:</div>
                        <div class="card-body p-2">
                            <div v-for="item in dataset.tags" :key="item.key" class="badge bg-primary rounded-pill mb-2 text-dark m-1">{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-3 row">
                <label for="url" class="col-sm-2 col-form-label">Publication web url:</label>
                <div class="col-sm-10">
                    <input v-model="dataset.url" type="text" class="form-control" id="url" placeholder="web url">
                </div>
            </div>

            <div class="mb-3 row">
                <label for="pdf" class="col-sm-2 col-form-label">Publication download url:</label>
                <div class="col-sm-10">
                    <input v-model="dataset.pdf" type="text" class="form-control" id="pdf" placeholder="pdf download url">
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-sm-12 d-md-flex justify-content-md-end">
                    <div v-if="loading" class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <button v-else class="btn btn-primary">Save</button>
                </div>
            </div>
        </form>
        <div v-if="dataItems.length > 0">
            <div class="mb-3 row" style="overflow-x:scroll;">
                <div>
                    <table class="table table-striped" style="width:auto;">
                        <thead class="table-primary">
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Protein</th>
                                <th scope="col">Gene</th>
                                <th v-for="mapping in columnMappings" v-bind:key="mapping.id" scope="col">{{ mapping.layer }}</th>
                            </tr>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th v-for="mapping in columnMappings" v-bind:key="mapping.id" scope="col"><div v-if="mapping.sample">{{mapping.sample.replace(" ", "\xa0")}}</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in dataItems" v-bind:key="item.id">
                                <th scope="col">{{item.id}}</th>
                                <th scope="col">{{item.protein}}</th>
                                <th scope="col">{{item.gene}}</th>
                                <td v-for="mapping in columnMappings" v-bind:key="mapping.id" scope="col">{{item[mapping.name.toLowerCase()]}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-sm-12 d-md-flex justify-content-md-end">
                    <div class="btn-group">
                        <button @click="loadPrev()" class="btn btn-outline-primary">Prev</button>
                        <button @click="loadNext()" class="btn btn-outline-primary">Next</button>
                    </div>
                </div>

                <div class="col-sm-12 d-md-flex justify-content-md-end py-5">
                    <div class="btn-group">
                        <button @click="openDeleteModal()" class="btn btn-danger">Delete data</button>
                    </div>
                </div>

            </div>
        </div>
        <div v-else>
            <div class="mb-3 row">
                <label for="data" class="col-sm-2 col-form-label">Data file (<a href="#">format details here</a>):</label>
                <div v-if="parsing" class="col-sm-2">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <div v-else class="col-sm-2">
                    <button class="btn btn-primary" @click="onPickFile">Choose file</button>
                    <input type="file"
                           style="display: none"
                           ref="fileInput"
                           accept="csv/*"
                           @change="onFilePicked" />
                </div>
                <div class="col-sm-5">
                    <pre style="max-height: 300px; overflow: auto;">{{fileParseMessage}}</pre>
                </div>
            </div>

            <div class="mb-3 row">
                <div class="col-sm-10">
                    <pre>{{saveMessage}}</pre>
                </div>
                <div class="col-sm-2 d-md-flex justify-content-md-end">
                    <div v-if="loading" class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    <button v-else class="btn btn-primary" @click="saveDataItems()">Save Data Items</button>
                </div>
            </div>
        </div>

        <div class="modal" ref="modal" id="dialogYesNo" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Skin Atlas</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to delete all data for this dataset?</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button v-if="!deleting" type="button" @click="deleteData()" data-bs-dismiss="modal" class="btn btn-danger"> Yes </button>
                        <div v-else class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import * as Papa from 'papaparse'
    import { authHeader } from '@/services/authHeader.js';
    import { toastOptions } from '@/helpers/toastOptions.js';
    import Modal from 'bootstrap/js/dist/modal';
    let tags = '';

    const options = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    }

    export default {
        name: 'Dataset',
        data() {
            return {
                fileParseMessage: '',
                parsing: false,
                tags: tags,
                loading: false,
                saveMessage: '',
                dataset: {
                    id: 0,
                    name: '',
                    image: '',
                    shortDescription: '',
                    tags: [],
                    url: '',
                    pdf: ''
                },
                page: 1,
                dataItems: [],
                columnMappings: [],
                images: [],
                modal: null,
                deleting: false
            }
        },
        methods: {
            onPickFile() {
                this.$refs.fileInput.click();
                this.parsing = true;
            },
            parseCsvData(results) {
                try {
                    let columns = [];
                    let proteins = [];
                    let index = 0;

                    results.data.forEach(item => {

                        if (index > 1) {
                            if (item[0]) {
                                let column = { name: "D" + (index - 1), layer: item[0], sample: item[1], colorId: item[2] };
                                columns.push(column);
                            }
                        }

                        for (var i = 3; i <= item.length - 1; i++) {
                            if (index == 0) {
                                proteins.push({ id: 0, protein: item[i] });
                            }
                            else if (index == 1) {
                                proteins[i - 3]["gene"] = item[i];
                            }
                            else {
                                proteins[i - 3][`D${index - 1}`] = item[i];
                            }
                        }

                        index++;
                    });

                    this.dataset.mapping = columns;
                    this.dataset.dataItems = proteins;

                    //console.log(this.dataset.mapping);
                    //console.log(this.dataset.dataItems);

                    this.fileParseMessage = `Found ${columns.length} samples and ${proteins.length} proteins.\n`;

                    for (var i = 0; i < columns.length; i++) {
                        this.fileParseMessage += `\ncolumn:${columns[i].name}\tlayer:${columns[i].layer}\tsample:${columns[i].sample}`;
                    }
                }
                catch (e) {
                    this.fileParseMessage = "Error parsing file:" + e.message;
                }
                this.parsing = false;
            },
            saveDataItems() {
                this.loading = true;
                axios.put(process.env.VUE_APP_URL + `api/dataitems/${this.dataset.id}`, this.dataset, options)
                    .then(() => {
                        //console.log(response.data);
                        this.saveMessage = "Data items saved";
                    })
                    .catch(error => {
                        this.saveMessage = error.message;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onFilePicked(event) {

                const files = event.target.files
                this.fileParseMessage = files[0].name;
                if (!files.length) {
                    //console.log("choose file");
                    this.fileParseMessage = "Please choose a file";
                    return;
                }
                Papa.parse(files[0], {
                    complete: this.parseCsvData
                });
            },
            loadDataItems(page) {
                axios.get(process.env.VUE_APP_URL + `api/dataitems/dataset/${this.$route.query.id}/${page}`)
                    .then(response => {
                        //console.log("loading next page " + page);
                        //console.log(response.data);
                        this.dataItems = response.data;
                    })
                    .catch(error => {
                        this.$toast(error.message, toastOptions.error);
                    })
                    .finally(() => {

                    });
            },

            loadNext() {
                this.page++;
                //console.log("loading next page " + this.page);
                this.loadDataItems(this.page);
            },

            loadPrev() {
                this.page--;
                //console.log("loading next page " + this.page);
                this.loadDataItems(this.page);
            },

            handleSubmit() {
                //console.log("posting");
                //console.log(this.dataset);
                this.loading = true;
                const options = {
                    headers: { ...authHeader(), 'Content-Type': 'application/json' }
                }
                axios.put(process.env.VUE_APP_URL + `api/datasets/${this.dataset.id}`, this.dataset, options)
                    .then(response => {
                        this.dataset = response.data;
                        this.$router.push('admindatasets');
                    })
                    .catch(error => {
                        this.loading = false;
                        this.$toast(error.message, {
                            duration: 5000,
                            styles: {
                                borderRadius: '25px'
                            },
                            class: 'local-class', // Added to this toast only
                            type: 'error', // Default classes: 'success', 'error' and 'passive'
                            positionX: 'center',
                            positionY: 'top',
                            disableClick: true
                        });
                    });
            },

            openDeleteModal() {
                this.modal.show();
            },

            async deleteData() {
                this.deleting = true;
                await axios.delete(process.env.VUE_APP_URL + `api/DataItems/dataset/${this.$route.query.id}`, options)
                    .then(() => {
                        this.$toast("Items deleted", toastOptions.success);
                        this.getData();
                    })
                    .catch(error => {
                        this.$toast(error.message, toastOptions.error);
                    })
                    .finally(() => {
                        this.deleting = false;
                        this.modal.hide();
                    });
            },

            determineTagsArray() {
                this.dataset.tags = this.tags.trim().split(/[',']/).map(i => ({ datasetId: this.dataset.id, name: i.trim() }));
            },
            getData() {
                if (this.$route.query.id) {
                    axios.get(process.env.VUE_APP_URL + `api/datasets/${this.$route.query.id}`)
                        .then(response => {
                            this.dataset = response.data;
                            this.tags = this.dataset.tags.map(t => t.name).join(", ");
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        })
                        .finally(() => {
                            this.loaded = true;
                        });

                    this.loadDataItems(1);

                    axios.get(process.env.VUE_APP_URL + `api/columnmappings/${this.$route.query.id}`)
                        .then(response => {
                            this.columnMappings = response.data;
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        });

                    axios.get(process.env.VUE_APP_URL + `api/imagemanager`)
                        .then(response => {
                            this.images = response.data;
                        })
                        .catch(error => {
                            this.$toast(error.message, toastOptions.error);
                        });
                }
            }
        },
        watch: {
            'tags': {
                handler: function () {
                    this.determineTagsArray();
                },
                deep: true,
                immediate: true
            }
        },
        beforeMount() {
            this.getData();
        },
        mounted() {
            this.modal = new Modal(this.$refs.modal);
        }
    }
</script>